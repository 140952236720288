<template>
	<div class="side-bar-backdrop" :class="{'opened': opened}" @click="opened = false"></div>
	<div class="side-bar-menu">
		<div class="side-bar-wrap">
			<div class="side-bar-toggle" @click="opened = true">
				<div class="icon"><img src="../assets/icons/profile.svg" alt="avatar"></div>
			</div>
			
			<div class="side-bar-body" :class="{'opened': opened}">
				<div class="user-wrap">
					<div class="icon"><img src="../assets/icons/profile.svg" alt="avatar"></div>
					<div class="name">{{ userName }}</div>
					<div class="number">{{ userNumber }}</div>
				</div>
				
				<div class="logout-wrap" @click="$root.logout">
					<div class="icon"><i class="bi bi-box-arrow-right"></i></div>
					<div class="logout-label">{{ $t('logout') }}</div>
				</div>
			
			</div>
			
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			opened: false
		}
	},
	mounted() {
		this.opened = false
	},
	computed: {
		userName() {
			return this.$store.getters.user?.name
		},
		userNumber() {
			return this.$store.getters.user?.personal_number
		}
	}
}
</script>

<style lang="scss" scoped>
#zmen-form .side-bar-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 9;
	background: rgba(0, 0, 0, .4);
	transition: opacity .4s ease-in-out;
	pointer-events: none;
	
	&.opened {
		opacity: 1;
		pointer-events: auto;
	}
}

#zmen-form .side-bar-menu::v-deep(.side-bar-wrap) {
	position: relative;
	
	.side-bar-toggle {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 35px;
		height: 35px;
		margin-top: 20px;
		margin-left: var(--zmen-form-container);
		background: var(--zmen-form-gradient-dark);
		border-radius: 50%;
		
		.icon {
			img {
				width: 18px;
				height: 18px;
				object-fit: contain;
			}
		}
	}
	
	.side-bar-body {
		position: absolute;
		top: 0;
		left: -100%;
		width: 300px;
		max-width: 80%;
		z-index: 10;
		background: var(--zmen-form-gradient-dark);
		padding: 20px 30px;
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;
		transition: left .4s ease-in-out;
		
		&.opened {
			left: 0;
		}
		
		.user-wrap {
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;
			
			.icon {
				margin-bottom: 10px;
				
				img {
					width: 20px;
					height: 20px;
					object-fit: contain;
				}
			}
			
			.name {
				margin-bottom: 6px;
				color: white;
				font-size: 20px;
			}
			
			.number {
				color: white;
				font-size: 13px;
			}
		}
		
		.logout-wrap {
			display: flex;
			flex-direction: row;
			align-items: center;
			color: white;
			
			.icon {
				margin-right: 10px;
				font-size: 25px;
			}
			
			.logout-label {
				font-size: 15px;
				text-transform: uppercase;
			}
		}
	}
}
</style>