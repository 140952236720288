<template>
<!--	<select ref="userSelect" class="user-select2"/>-->
	<div class="select-wrap">
		<div class="select-inner-wrap">
			<VueSelect ref="userSelect" class="user-select2" v-model="value" :options="userSelectOptions" @search="searchUsers">
				<template v-slot:selected-option="option">
					{{ option.name }}<br>
					{{ option.personal_number }}
				</template>
				<template #no-options>{{ $t('idea.searchUserSelect') }}</template>
			</VueSelect>
		</div>
	</div>
</template>

<script>
// import $ from 'jquery';
// import 'select2/dist/js/select2.full';
// import 'select2/dist/css/select2.min.css'

import VueSelect from 'vue-select';
import "vue-select/dist/vue-select.css";

export default {
	props: ['modelValue'],
	components: {VueSelect},
	data() {
		// let self = this;
		return {
			userSelect: false,
			userSelectOptions: [],
			
			// userSelectSettings: {
			// 	width: 'style',
			// 	minimumInputLength: 2,
			// 	templateSelection: function (data) {
			// 		console.log('selected format', data)
			// 		return data.text
			// 	},
			// 	ajax: {
			// 		url: self.$config.API_URL + '/users',
			// 		dataType: 'json',
			// 		delay: 800,
			// 		data: function (params) {
			// 			const query = {
			// 				per_page: 10
			// 			}
			//
			// 			if (params.term?.length > 0) {
			// 				query['query'] = params.term
			// 			}
			//
			// 			// Query parameters will be ?search=[term]&type=public
			// 			return query
			// 		},
			// 		processResults: function (data) {
			// 			// Transforms the top-level key of the response object from 'items' to 'results'
			// 			console.log(data)
			//
			// 			return {
			// 				results: $.map(data.data, function (item) {
			// 					return {
			// 						id: item.id,
			// 						text: item.name
			// 					}
			// 				})
			// 			}
			// 		}
			// 	}
			// }
		}
	},
	// mounted() {
		// this.initUserSelect()
	// },
	methods: {
		searchUsers(search, loading) {
			if (search?.length === 0) return
			
			loading(true)
			
			this.$axios.get(`${this.$config.API_URL}/users?per_page=10&query=${search}`, this.$config.JS_HEADERS)
				.then((res) => {
					console.warn(res)
					
					this.userSelectOptions = res.data.data.map(item => {
						return {
							id: item.id,
							label: `${item.name} - ${item.personal_number}`,
							name: item.name,
							personal_number: item.personal_number
						}
					})
				})
				.finally(() => {
					loading(false)
				})
		},
		
		// selectAuthor() {
		// 	this.$emit('select')
		// },
		
		// deleteAuthor() {
		// 	console.log('change', this.modelValue)
			// if (!this.modelValue) {
			// 	this.$emit('delete')
			// }
		// }
		
		// initUserSelect() {
		// 	let self = this;
		//
		// 	this.userSelectSettings.dropdownParent = document.querySelector('#zmen-form .page-idea-form .idea-form-wrap')
		// 	// this.userSelectInit = true
		//
		// 	this.userSelect = $(this.$el)
		// 		.select2(this.userSelectSettings)
		// 		.val(this.modelValue)
		// 		.trigger('change')
		// 		.on('select2:select', function (data) {
		// 			self.onUserSelect(data.params.data)
		// 		})
		//
		// 	// this.$nextTick(() => {
		// 	// 	let userSelectEl = this.$refs.userSelect
		// 	// 	// console.log(userSelectEl.$el)
		// 	// 	$(userSelectEl).on('select2:open', function () {
		// 	// 		// console.log('open')
		// 	// 		let input = $('.select2-container .select2-search__field')[0]
		// 	// 		// console.log('input', input)
		// 	// 		input.focus()
		// 	// 	})
		// 	//
		// 	// 	$(userSelectEl).on('select2:select', function (e) {
		// 	// 		self.onUserSelect(e.params.data)
		// 	// 	})
		// 	// })
		// },
		
	},
	computed: {
		value: {
			get() {
				return this.modelValue
			},
			set(val) {
				if (val) {
					// console.log('select', this.modelValue, val)
					this.$emit('update:modelValue', val)
					this.$emit('select')
				} else {
					// console.log('delete')
					this.$emit('delete')
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
#zmen-form .select-wrap {
	height: 100%;
	width: 100%;
}

#zmen-form .select-wrap::v-deep(.select-inner-wrap) {
	height: inherit;
	width: inherit;
	
	.v-select {
		height: inherit;
		width: inherit;
		
		font-size: 14px;
		color: white;
		
		.vs__selected {
			color: white;
		}
		
		// fix bug for white space in select when selected item is longer
		.vs__selected-options {
			.vs__search {
				position: absolute;
				background: none;
				border: none;
			}
			
			.vs__search:focus {
				position: relative !important;
			}
		}
		
		.vs__actions {
			.vs__clear {
				display: flex;
				margin-right: 15px;
				fill: white;
			}
			
			.vs__open-indicator {
				fill: white;
			}
		}
		
		.vs__dropdown-toggle {
			height: inherit;
			background: var(--zmen-form-input-background);
			border-radius: var(--zmen-form-border-radius);
		}
		
		ul.vs__dropdown-menu {
			padding-left: 0;
			background: var(--zmen-form-background-gradient-dark);
			
			.vs__dropdown-option {
				padding-left: 10px;
				
				&.vs__dropdown-option--highlight {
					background: rgba(64, 73, 84, .2);
				}
			}
		}
	}
}

</style>