<template>
	<div class="page-idea-form">
		<div class="idea-form-wrap">
			
			<SideBarMenu />
			
			<div class="form-wrap">
				<div class="form-item">
					<div class="form-floating">
						<IdeaTypeSelect id="ideaTypeFloat" v-model="ideaForm.ideaType" />
						<label for="ideaTypeFloat">{{ $t('idea.ideaType') }}</label>
					</div>
					<div v-if="v$.ideaForm.ideaType.$error && v$.ideaForm.ideaType.required.$invalid" class="invalid-feedback">
						{{ $t('validation.required') }}
					</div>
				</div>
				
				<div class="form-item">
					<div class="form-floating">
						<input class="form-control" type="text" id="titleFloat" :placeholder="$t('idea.title')" v-model="ideaForm.title">
						<label for="titleFloat">{{ $t('idea.title') }}</label>
					</div>
					<div v-if="v$.ideaForm.title.$error && v$.ideaForm.title.required.$invalid" class="invalid-feedback">
						{{ $t('validation.required') }}
					</div>
				</div>
				
				<div class="form-item">
					<label for="situationFloat">{{ $t('idea.situation') }}</label>
					<textarea class="form-control" id="situationFloat" v-model="ideaForm.situation"></textarea>
					<div v-if="v$.ideaForm.situation.$error && v$.ideaForm.situation.required.$invalid" class="invalid-feedback">
						{{ $t('validation.required') }}
					</div>
				</div>
				
				<div class="form-item">
					<label for="descriptionFloat">{{ $t('idea.description') }}</label>
					<textarea class="form-control" id="descriptionFloat" v-model="ideaForm.description"></textarea>
					<div v-if="v$.ideaForm.description.$error && v$.ideaForm.description.required.$invalid" class="invalid-feedback">
						{{ $t('validation.required') }}
					</div>
				</div>
				
				<div class="form-item form-attachments">
					<label>{{ $t('idea.attachments') }}</label>
					<div class="attachments-list">
						<div class="attachment" v-for="(attachment, index) in ideaForm.attachments" :key="attachment.id">
							<input class="form-control" :ref="'attachment_gallery_' + index" type="file" @change="changeAttachment($event, index)" accept="image/*">
							
							<input class="form-control android-photo-capture" :ref="'attachment_capture_' + index" type="file" @change="changeCaptureAttachment($event, index)" accept="image/*" capture="">
							<button class="android-photo-options-toggle" @click="androidPhotoOptions[index] = !androidPhotoOptions[index]"></button>
							<div class="android-photo-options" :class="{'show': androidPhotoOptions[index]}">
								<div class="gallery" @click="focusGallery(index)"><i class="bi bi-images"></i> {{ $t('idea.fromGallery') }}</div>
								<div class="capture" @click="focusCaptureInput(index)"><i class="bi bi-camera"></i> {{ $t('idea.capture') }}</div>
							</div>
							
							<div v-if="index !== ideaForm.attachments.length - 1" class="btn-remove-attachment" @click="deleteAttachment(index)"></div>
						</div>
					</div>
					
				</div>
				
				<div class="authors-heading">
					<div class="title-authors">{{ $t('idea.authors') }}</div>
					<div class="spacer"></div>
					<div class="title-reward">{{ $t('idea.reward') }}</div>
				</div>
				
				<div class="authors-list">
					<div class="author-item" v-for="(author, index) in ideaForm.authors" :key="author.id">
						<div class="author-index">{{ index + 1 }}</div>
						
						<div v-if="author.owner" class="owner">
							<div class="user">
								<div class="user-name">{{ author.name }}</div>
								<div class="user-number">{{ author.number }}</div>
							</div>
<!--							<div class="reward">{{ author.reward }}%</div>-->
							<div class="reward">
								<input class="form-control" type="number" v-model="author.reward" :max="author.rewardMax" @input="divideReward(author)">
							</div>
						</div>
						
						<div v-if="!author.owner" class="not-owner">
							<div class="user">
								<AuthorSelect class="user-select2" v-model="author.data" @select="selectedAuthor" @delete="deleteAuthor(index)" />
<!--								<select ref="userSelect" class="user-select2"/>-->
							</div>
							<div class="reward">
								<input class="form-control" type="number" v-model="author.reward" :max="author.rewardMax" @input="divideReward(author)">
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<input class="visually-hidden" v-model="ideaForm.totalReward">
			<div v-if="v$.ideaForm.totalReward.$error && v$.ideaForm.totalReward.rewardValidation.$invalid" class="invalid-feedback total-reward-feedback">
				{{ $t('validation.reward') }}
			</div>
			
			<div v-if="loading" class="loading spinner-border" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
			
			<button :disabled="loading" type="button" class="btn btn-primary-gradient btn-submit" @click="submitIdea">{{ $t('idea.submit') }}</button>
			
			<div v-if="ideaSuccess" class="valid-feedback submit-feedback">
				{{ $t('idea.success') }}
			</div>
			<div v-if="ideaError" class="invalid-feedback submit-feedback">
				{{ $t('validation.serverError') }}
			</div>
			
		</div>
	</div>
</template>

<script>

// import {ModelSelect} from "vue-search-select";
// import Select2 from "vue3-select2-component"
import IdeaTypeSelect from "../components/IdeaTypeSelect.vue";
import AuthorSelect from "../components/AuthorSelect.vue";
import SideBarMenu from "@/components/SideBarMenu.vue";

import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
	components: {IdeaTypeSelect, SideBarMenu, AuthorSelect},
	setup() {
		return {
			v$: useVuelidate()
		}
	},
	data() {
		return {
			
			defaults: {
				ideaForm: {
					ideaType: '',
					title: '',
					situation: '',
					description: '',
					attachments: [],
					authors: [],
					totalReward: 100
				},
				
				attachments: {
					image: ''
				},
				authors: {
					data: null,
					reward: 0,
					owner: false
				},
				authorsTotalReward: 100,
			},
			
			ideaForm: {
				ideaType: '',
				title: '',
				situation: '',
				description: '',
				attachments: [],
				authors: [],
				totalReward: 100
			},
			
			loading: false,
			androidPhotoOptions: {},
			
			ideaSuccess: false,
			ideaError: false
		}
	},
	validations() {
		const rewardValidation = (value) => value === 100
		
		return {
			ideaForm: {
				ideaType: {required},
				title: {required},
				situation: {required},
				description: {required},
				totalReward: {rewardValidation}
			}
		}
	},
	
	mounted() {
		this.initDefaults()
	},
	
	methods: {
		submitIdea() {
			this.ideaSuccess = false
			this.ideaError = false
			
			this.v$.ideaForm.$touch()
			if (!this.v$.ideaForm.$error) {
				this.createIdea()
			}
		},
		createIdea() {
			this.loading = true
			
			let body = new FormData();
			
			body.append('type', this.ideaForm.ideaType.id)
			body.append('title', this.ideaForm.title)
			body.append('situation', this.ideaForm.situation)
			body.append('description', this.ideaForm.description)
			
			this.ideaForm.attachments.forEach((item, imageIndex) => {
				if (item.image) {
					body.append(`images[${imageIndex}]`, item.image)
				}
			})
			
			this.ideaForm.authors.forEach((item, authorIndex) => {
				if (item.data?.id) {
					body.append(`users[${authorIndex}][id]`, item.data.id)
					body.append(`users[${authorIndex}][reward]`, item.reward)
				}
			})
			
			this.$axios.post(`${this.$config.API_URL}/ideas`, body, this.$config.FD_HEADERS)
				.then((res) => {
					console.log(res)
					this.ideaSuccess = true
					this.clearIdeaForm()
				}, (err) => {
					console.log(err)
					this.ideaError = true
				}).finally(() => {
					this.loading = false
				})
		},
		
		clearIdeaForm() {
			this.v$.ideaForm.$reset()
			this.ideaForm = JSON.parse(JSON.stringify(this.defaults.ideaForm))
			this.initDefaults()
		},
		
		initDefaults() {
			const rewardMax = JSON.parse(JSON.stringify(this.defaults.authorsTotalReward))
			
			this.ideaForm.attachments.push({
				id: crypto.randomUUID(),
				image: ''
			})
			
			this.ideaForm.authors.push({
				id: crypto.randomUUID(),
				data: {
					id: this.$store.getters.user?.id,
				},
				name: this.$store.getters.user?.name,
				number: this.$store.getters.user?.personal_number,
				reward: 100,
				rewardMax: rewardMax,
				owner: true
			}, {
				id: crypto.randomUUID(),
				data: null,
				reward: 0,
				rewardMax: 0,
				owner: false
			})
		},
		
		divideReward(author) {
			const rewardMax = JSON.parse(JSON.stringify(this.defaults.authorsTotalReward))
			
			if (author.reward > author.rewardMax) {
				author.reward = author.rewardMax
			} else if (author.reward < 0) {
				author.reward = 0
			}
			
			const total = this.totalAvailableReward()
			
			this.ideaForm.authors.map(item => {
				const max = JSON.parse(JSON.stringify(Number(item.reward) + Number(rewardMax) - total ))
				
				console.log(item, max)
				
				item.rewardMax = max
			})
		},
		
		totalAvailableReward() {
			let sum = 0
			this.ideaForm.authors.forEach(item => {
				sum += Number(item.reward)
			})
			console.log('total available reward', sum)
			
			// this.ideaForm.totalReward = sum
			this.totalReward()
			return sum
		},
		
		totalReward() {
			let sum = 0
			this.ideaForm.authors.forEach(item => {
				if (item.data) {
					sum += Number(item.reward)
				}
			})
			console.log('total submittable reward', sum)
			
			this.ideaForm.totalReward = sum
		},
		
		changeAttachment(e, index) {
			const files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			this.ideaForm.attachments[index].image = files[0]
			
			// add empty input
			if (this.ideaForm.attachments[this.ideaForm.attachments.length - 1].image) {
				const newAttachment = JSON.parse(JSON.stringify(this.defaults.attachments))
				newAttachment.id = crypto.randomUUID()
				this.ideaForm.attachments.push(newAttachment)
			}
		},
		changeCaptureAttachment(e, index) {
			const files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			this.ideaForm.attachments[index].image = files[0]
			// set gallery input files
			this.$refs['attachment_gallery_' + index][0].files = files
			
			// add empty input
			if (this.ideaForm.attachments[this.ideaForm.attachments.length - 1].image) {
				const newAttachment = JSON.parse(JSON.stringify(this.defaults.attachments))
				newAttachment.id = crypto.randomUUID()
				this.ideaForm.attachments.push(newAttachment)
			}
		},
		
		deleteAttachment(index) {
			console.log('delete', index)
			// if only one attachment, set default
			if (this.ideaForm.attachments.length === 1) {
				this.ideaForm.attachments = []
				const newAttachment = JSON.parse(JSON.stringify(this.defaults.attachments))
				newAttachment.id = crypto.randomUUID()
				this.ideaForm.attachments.push(newAttachment)
				
			// else delete attachment by index
			} else {
				const attachments = JSON.parse(JSON.stringify(this.ideaForm.attachments))
				this.ideaForm.attachments = attachments.filter((item, ind) => ind !== index)
			}
			
		},
		
		selectedAuthor() {
			// if last author is set, add empty author
			if (this.ideaForm.authors[this.ideaForm.authors.length - 1].data) {
				const newAuthor = JSON.parse(JSON.stringify(this.defaults.authors))
				newAuthor.id = crypto.randomUUID()
				this.ideaForm.authors.push(newAuthor)
			}
			
			this.totalReward()
		},
		
		deleteAuthor(index) {
			const authors = JSON.parse(JSON.stringify(this.ideaForm.authors))
			this.ideaForm.authors = authors.filter((author, ind) => ind !== index)
			
			this.totalReward()
		},
		
		focusGallery(i) {
			this.$refs['attachment_gallery_' + i][0].click()
			this.androidPhotoOptions[i] = false
		},
		focusCaptureInput(i) {
			this.$refs['attachment_capture_' + i][0].click()
			this.androidPhotoOptions[i] = false
		}
	}
}
</script>

<style lang="scss" scoped>
#zmen-form .page-idea-form::v-deep(.idea-form-wrap) {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding-bottom: 30px;
	background: linear-gradient(to bottom, var(--zmen-form-background-gradient-light), var(--zmen-form-background-gradient-dark));
	
	.form-wrap {
		.form-item {
			margin-left: var(--zmen-form-container);
			margin-right: var(--zmen-form-container);
		}
	}
	
	.form-attachments {
		display: flex;
		flex-direction: column;
		margin-bottom: 40px;
		
		.attachments-list {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin-left: auto;
			
			.attachment {
				position: relative;
				display: flex;
				flex-direction: row;
				align-items: center;
				
				&:not(:last-child) {
					margin-bottom: 15px;
				}
				
				input {
					color: var(--zmen-form-text-light);
					font-size: 11px;
					font-style: italic;
					width: 100%;
					
					&:hover {
						border-color: var(--zmen-form-input-background-hover);
					}
				}
				
				.android-photo-capture {
					display: none;
				}
				
				.android-photo-options-toggle {
					position: absolute;
					width: calc(100% - 50px);
					height: 100%;
					//display: flex;
					display: none;
					opacity: 0;
				}
				
				.android-photo-options {
					position: absolute;
					top: 100%;
					display: none;
					flex-direction: column;
					z-index: 20;
					
					&.show {
						display: flex;
					}
					
					> div {
						padding: 8px 40px 8px 10px;
						background: var(--zmen-form-background-gradient-dark);
						color: white;
						
						i {
							margin-right: 5px;
						}
					}
				}
				
				.btn-remove-attachment {
					position: relative;
					flex-shrink: 0;
					width: 20px;
					height: 20px;
					background: red;
					border-radius: 50%;
					
					&:before {
						content: "";
						position: absolute;
						top: 9px;
						left: 4px;
						width: 12px;
						height: 2px;
						background: white;
					}
				}
			}
		}
	}
	
	.authors-heading {
		display: flex;
		flex-direction: row;
		
		.title-authors, .title-reward {
			position: relative;
			color: white;
			font-size: 13px;
			padding-bottom: 8px;
			
			&:after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				height: 5px;
				width: 100%;
			}
		}
		
		.title-authors {
			flex-basis: 120px;
			padding-left: 61px;
			
			&:after {
				background: linear-gradient(to left, var(--zmen-form-border-gradient-light), var(--zmen-form-border-gradient-dark));
			}
		}
		
		.spacer {
			flex-basis: calc(100% - (120px * 2));
		}
		
		.title-reward {
			flex-basis: 120px;
			padding-right: var(--zmen-form-container);
			text-align: right;
			
			&:after {
				background: linear-gradient(to right, var(--zmen-form-border-gradient-light), var(--zmen-form-border-gradient-dark));
			}
		}
	}
	
	.authors-list {
		display: flex;
		flex-direction: column;
		margin: 25px 0;
		
		.author-item {
			display: flex;
			flex-direction: row;
			
			&:not(:last-child) {
				margin-bottom: 25px;
			}
			
			.author-index {
				flex-basis: 50px;
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				padding-right: 10px;
				border: 1px solid var(--zmen-form-border-color-dark);
				border-top-right-radius: var(--zmen-form-border-radius);
				border-bottom-right-radius: var(--zmen-form-border-radius);
				color: white;
			}
			
			.owner, .not-owner {
				display: flex;
				flex-direction: row;
				flex-basis: calc(100% - 50px);
				margin-left: 10px;
				color: white;
				
				.user {
					display: flex;
					flex-grow: 1;
					flex-basis: calc(100% - 65px);
					width: 100%;
					min-height: 51px;
				}
				
				.reward {
					position: relative;
					
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
					flex-basis: 65px;
					flex-shrink: 0;
					margin-left: 10px;
					//margin-right: var(--zmen-form-container);
					margin-right: 15px;
					
					&:after {
						content: "%";
						position: absolute;
						top: 50%;
						right: 8px;
						font-size: 13px;
						transform: translate(0, -50%);
					}
					
					input {
						padding: 8px 20px 8px 12px;
						background: var(--zmen-form-input-background);
						font-size: 16px;
						color: white;
						border-radius: 10px;
						
						&::-webkit-outer-spin-button,
						&::-webkit-inner-spin-button {
							/* display: none; <- Crashes Chrome on hover */
							-webkit-appearance: none !important;
							margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
						}
					}
				}
			}
			
			.owner {
				.user {
					flex-direction: column;
					justify-content: center;
					
					.user-name {
						margin-bottom: 3px;
						font-size: 17px;
						font-weight: 500;
					}
					
					.user-number {
						font-size: 13px;
					}
				}
				
			}
			
			.not-owner {
				display: flex;
				flex-direction: row;
				flex-basis: calc(100% - 50px);
				margin-left: 10px;
				color: white;
			}
		}
	}
	
	.submit-feedback,
	.total-reward-feedback {
		padding-left: var(--zmen-form-container);
		padding-right: var(--zmen-form-container);
		text-align: center;
	}
	
	.btn-submit {
		align-self: center;
		width: calc(100% - 120px);
		max-width: 280px;
		margin-top: 20px;
		margin-bottom: 10px;
	}
}
</style>